import React from "react"
import { Grid } from "../../../../components/general/Grid/Grid"
import { Section } from "../../../../components/general/Section/Section"
import {
  BoldParagraphTitle,
  SectionParagraph,
} from "../../../../components/general/Typography/Typography"
import { TypographyAnimation } from "../../../../components/general/TypographyAnimation/TypographyAnimation"
import { Color, GrayGridTheme } from "../../../../data/style/variables"
import {
  DataImageContainer,
  IllustrationsContainerElement,
  WaveElement,
} from "../styled"
import launchSvg from "../../../../images/Illustrations/Resized/business-launch.svg"
import analysisSvg from "../../../../images/Illustrations/Resized/business-analysis.svg"
import interviewSvg from "../../../../images/Illustrations/Resized/business-interview.svg"
import linesSvg from "../../../../images/SVG/Vector 7.svg"

export const DataIllustrationsSection: React.FC = () => {
  return (
    <IllustrationsContainerElement>
      <Grid gridElementProps={{ baseHeight: "250px", ...GrayGridTheme }}>
        <WaveElement src={linesSvg} />
      </Grid>
      <Grid gridElementProps={{ baseHeight: "750px", ...GrayGridTheme }}>
        <Section sectionElementProps={{ color: Color.Primary }}>
          <DataImageContainer src={launchSvg}/>
          <TypographyAnimation>
            <BoldParagraphTitle color={Color.Primary}>
              Controleren van producten en data verzamelen
            </BoldParagraphTitle>
            <SectionParagraph color={Color.Secondary}>
              Per categorie kan er een analyse gedaan worden om de pijnpunten
              binnen dit proces te tonen.
            </SectionParagraph>
          </TypographyAnimation>
        </Section>
        <Section sectionElementProps={{ color: Color.Primary }}>
          <DataImageContainer src={analysisSvg} />
          <TypographyAnimation>
            <BoldParagraphTitle color={Color.Primary}>
              Data analyseren en valkuilen in het proces ontdekken
            </BoldParagraphTitle>
            <SectionParagraph color={Color.Secondary}>
              Elk product in een productieproces bestaat uit een aantal
              processen hierdoor is het mogelijk om de data te groeperen.
            </SectionParagraph>
          </TypographyAnimation>
        </Section>
        <Section sectionElementProps={{ color: Color.Primary }}>
          <DataImageContainer src={interviewSvg} />
          <TypographyAnimation>
            <BoldParagraphTitle color={Color.Primary}>
              Productieprocessen verbeteren op basis van de data
            </BoldParagraphTitle>
            <SectionParagraph color={Color.Secondary}>
              Het ontdekken van herhalende fouten binnen een proces is gemakkelijk en overzichtelijk.
            </SectionParagraph>
          </TypographyAnimation>
        </Section>
      </Grid>
    </IllustrationsContainerElement>
  )
}
