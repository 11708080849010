import React from "react"
import { Grid } from "../../../../components/general/Grid/Grid"
import { Section } from "../../../../components/general/Section/Section"
import { SummaryItem } from "../../../../components/general/SummaryItem/SummaryItem"
import {
  SectionParagraph,
  Typography,
  TypographyTypes,
} from "../../../../components/general/Typography/Typography"
import { TypographyAnimation } from "../../../../components/general/TypographyAnimation/TypographyAnimation"
import {
  Color,
  FontFamily,
  FontWeight,
  WhiteGridTheme,
} from "../../../../data/style/variables"
import {
  BoldTextElement,
  ContainerElementProductInfo,
  WideSectionElement,
} from "../styled"

export const ProductInfoSection: React.FC = () => {
  return (
    <ContainerElementProductInfo>
      <Grid
        gridElementProps={{
          baseHeight: "calc(100vh - 160px)",
          ...WhiteGridTheme,
          noPadding: true,
          spaced: true,
        }}
      >
        <Section sectionElementProps={{ justifyCenter: true }}>
          <WideSectionElement>
            <TypographyAnimation>
              <Typography
                titleType={TypographyTypes.h2}
                fontFamily={FontFamily.CrimsonText}
                color={Color.Primary}
                fontWeight={FontWeight.SemiBold}
                fontSize={"9rem"}
                fontSizeMobile={"4.5rem"}
              >
                Fouten tot in het{" "}
                <span
                  style={{
                    fontFamily: FontFamily.CrimsonText,
                    color: Color.PrimaryOpague,
                  }}
                >
                  kleinste detail vastleggen
                </span>
              </Typography>
              <SectionParagraph color={Color.DarkGray}>
                Deze app kan door
                <BoldTextElement color={Color.Primary}>
                  {" "}
                  productiemedewerkers
                </BoldTextElement>{" "}
                gebruikt worden op de vloer, doormiddel van een tablet
                of telefoon. Hierdoor kan elke werknemer gemakkelijk zijn werk
                noteren en zijn progressie bijhouden. Voor elke order in uw
                systeem kan er automatisch een nieuwe checklist gegenereerd
                worden doormiddel van
                <BoldTextElement color={Color.Primary}>
                  {" "}
                  productcodes
                </BoldTextElement>
                . Door de data dagelijks te controleren is gemakkelijk om
                mederwerkers optijd bij te sturen. U kunt u klanten inzicht
                geven over het controle process hiermee kan de klant zekerheid
                krijgen in het ontwikkelproces van het product.
              </SectionParagraph>
            </TypographyAnimation>
          </WideSectionElement>
        </Section>
        <Section />
        <Section
          sectionElementProps={{ alignCenter: true, justifyCenter: true }}
        >
          <TypographyAnimation>
            <SummaryItem
              mainColor={Color.Primary}
              subColor={Color.DarkGray}
              label="01"
              content="Onmisbare tool om aan de ISO 9000 normering te voldoen."
            />
            <SummaryItem
              mainColor={Color.Primary}
              subColor={Color.DarkGray}
              label="02"
              content="Beperk productuitval tot een minimum doordat u op tijd kunt
              bijsturen."
            />
            <SummaryItem
              mainColor={Color.Primary}
              subColor={Color.DarkGray}
              label="03"
              content="Laagdrempelig en eenvoudig door productiemedewerkers te bedienen."
            />
          </TypographyAnimation>
        </Section>
      </Grid>
    </ContainerElementProductInfo>
  )
}
