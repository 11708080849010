import styled from "styled-components/macro";

export const ContainerElement = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ControlsContainerElement = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ImageContainerElement = styled.div`

`;