import styled from "styled-components/macro";
import { Color } from "../../../data/style/variables";

export const ContainerElement = styled.div`
  overflow: hidden;
`;

export const FooterImageElement = styled.div`
  height: calc(100% + 250px);
  top: -250px;
  position: absolute;
  left: 0;
  width: 100%;
  background: url("./photo-1581091212991-8891c7d4bd9b.jpg");
  background-size: cover;
`;

export const TitleCircleElement = styled.div`
  position: absolute;
  left: -25px;
`;

export const PhoneSlideDetailElement = styled.div`
  cursor: pointer;
`;

export const ScrollCTAArrow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  /* position: absolute;
  bottom: 75px; */
  height: 100%;
  display: flex;
  align-items: center;
`;

export const PowerBiWidgetContainer = styled.div``;

export const WidgetElement = styled.div`
  height: 300px;
`;

export const DataImageContainer = styled.img`
  margin: 0 auto;
  width: 100%;
  height: 222px;
  margin-bottom: 50px;
`;

export const IllustrationsContainerElement = styled.div`
  position: relative;
`;

export const WaveElement = styled.img`
  position: absolute;
  width: 100%;
  top: 0;
`;

export const WideSectionElement = styled.div`
  @media (min-width: 768px) {
    max-width: 200%;
    width: 200%;
  }
  padding: 10%;
  /* &::before {
    content: "";
    width: 125px;
    height: 125px;
    background: #0745af;
    position: absolute;
    left: -63px;
    top: 125px;
    border: 1px solid #2d61bb;
    
  }
  &::after {
    content: "";
    width: 125px;
    height: 125px;
    background: #0745af;
    position: absolute;
    right: -63.5px;
    bottom: 125px;
    border: 1px solid #2d61bb;
    transform: rotate(45deg);
  } */
`;

export const SummaryItemElement = styled.div`
  padding: 0 15px;
`;

type BoldTextElementProps = {
  color?: Color;
};

export const BoldTextElement = styled.b<BoldTextElementProps>`
  font-weight: 500;
  color: ${(props) => (props.color ? props.color : Color.Primary)};
`;
export const ContainerElementProductInfo = styled.div`
  display: flex;
`;
