import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Grid } from "../../../../components/general/Grid/Grid"
import { ResponsiveImage } from "../../../../components/general/ResponsiveImage/ResponsiveImage"
import { Section } from "../../../../components/general/Section/Section"
import {
  SectionParagraph,
  SectionParagraphTitle,
  SectionTitle
} from "../../../../components/general/Typography/Typography"
import { TypographyAnimation } from "../../../../components/general/TypographyAnimation/TypographyAnimation"
import {
  Color,
  GrayGridTheme,
  WhiteGridTheme
} from "../../../../data/style/variables"
import {
  TitleCircleElement
} from "../styled"

export const ProductDatalogicSection: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      datalogicScannerImage: file(
        relativePath: { eq: "datalogic-scanner-qr.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      datalogicLogoImage: file(relativePath: { eq: "Logos/datalogic.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <Grid
        gridElementProps={{
          spaced: true,
          baseHeight: "1100px",
          ...WhiteGridTheme,
        }}
      >
        <Section>
          <ResponsiveImage
            src={data.datalogicScannerImage.childImageSharp.fluid.src}
          />
        </Section>
        <Section>
          <TitleCircleElement>
            <img
              src={data.datalogicLogoImage.childImageSharp.fluid.src}
              width={50}
            />
          </TitleCircleElement>
          <TypographyAnimation>
            <SectionTitle color={Color.Primary}>
              Datalogic{" "}
              <span style={{ color: Color.PrimaryOpague }}>scanners</span>
            </SectionTitle>
            <>
              <SectionParagraphTitle color={Color.Primary}>
                Het volledige pakket
              </SectionParagraphTitle>
              <SectionParagraph color={Color.Secondary}>
                Bij het afnemen van onze checklist heeft u de mogelijkhed om ook
                datalogic scanners, tegen een scherpe prijs bij ons af te nemen.
                Deze scanners zijn robuust en kunnen tegen een stootje. Deze
                scanners zijn ook te combineren met onze andere software
                producten.
              </SectionParagraph>
            </>
            <>
              <SectionParagraphTitle color={Color.Primary}>
                Scannen van bar- en qrcodes
              </SectionParagraphTitle>
              <SectionParagraph color={Color.Secondary}>
                Met de scanner kunt u snel zoeken naar checklists door middel
                van de scan functie. Deze scanners zijn sneller dan de qr code
                scanner van een telefoon. De datalogic scanners kunnen zowel
                bar- als qr codes scannen.
              </SectionParagraph>
            </>
          </TypographyAnimation>
        </Section>
        <Section sectionElementProps={{ color: Color.Primary }}></Section>
      </Grid>
      <Grid
        gridElementProps={{
          spaced: true,
          baseHeight: "150px",
          ...GrayGridTheme,
        }}
      >
        <Section></Section>
        <Section></Section>
        <Section></Section>
      </Grid>
    </>
  )
}
