import styled from "styled-components/macro";
import { Color, FontFamily } from '../../../data/style/variables';

type InfoSummaryItemElementProps = {
  content: string;
  color: Color;
};

export const InfoSummaryItemElement = styled.div<InfoSummaryItemElementProps>`
  position: relative;
  &:before {
    content: ${(props) => `'${props.content}'`};
    color: ${props => props.color};
    font-size: 14px;
    position: absolute;
    left: -25px;
    top: -25px;
    font-family: ${FontFamily.RobotoMono};
  }
  margin: 35px;
  padding-right: 35px;
  width: 100%;
`;