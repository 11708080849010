import React from "react"
import { ContainerElement, ImageContainerElement } from "./styled"
import { ResponsiveImage } from "../ResponsiveImage/ResponsiveImage"

type ProductSlideShowProps = {
  slides: SlideType[]
  activePhoneSlideIndex: number
  maxWidth: number
}

export type SlideType = {
  imageUrl: string
  name: string
  text: string
}

export const ProductSlideShow: React.FC<ProductSlideShowProps> = ({
  slides,
  activePhoneSlideIndex,
  maxWidth,
}) => {
  return (
    <ContainerElement>
      <ImageContainerElement>
        <ResponsiveImage
          src={`${slides[activePhoneSlideIndex].imageUrl}`}
          maxWidth={maxWidth}
        />
      </ImageContainerElement>
    </ContainerElement>
  )
}
