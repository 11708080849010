import styled from "styled-components/macro";

type ResponsiveImageElementProps = {
  maxWidth?: number;
}

export const ResponsiveImageElement = styled.img<ResponsiveImageElementProps>`
  width: 100%;
  max-width: ${props => props.maxWidth ? `${props.maxWidth}px` : `none`};
  object-fit: cover;
`;