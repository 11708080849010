import React from "react"
import { Grid } from "../../../../components/general/Grid/Grid"
import { PageWidget } from "../../../../components/general/PageWidget/PageWidget"
import { ResponsiveImage } from "../../../../components/general/ResponsiveImage/ResponsiveImage"
import { Section } from "../../../../components/general/Section/Section"
import {
  SectionParagraph,
  SectionParagraphTitle,
  SectionTitle,
} from "../../../../components/general/Typography/Typography"
import { TypographyAnimation } from "../../../../components/general/TypographyAnimation/TypographyAnimation"
import { Color, GrayGridTheme } from "../../../../data/style/variables"
import {
  PowerBiWidgetContainer,
  TitleCircleElement,
  WidgetElement,
} from "../styled"
import { useStaticQuery, graphql } from "gatsby"
import { RoutePath } from '../../../../data/RoutePath';
import { PowerBi } from '../../PowerBi/PowerBi';

export const ProductDataSection: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      checklistCloudImage: file(relativePath: { eq: "ChecklistCloud.PNG" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      redCircleImage: file(relativePath: { eq: "RedCircle.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Grid gridElementProps={{ baseHeight: "850px", ...GrayGridTheme }}>
      <Section>
        <ResponsiveImage
          src={data.checklistCloudImage.childImageSharp.fluid.src}
        />
      </Section>
      <Section>
        <TitleCircleElement>
          <img
            src={data.redCircleImage.childImageSharp.fluid.src}
            width={50}
          />
        </TitleCircleElement>
        <TypographyAnimation>
          <SectionTitle color={Color.Primary}>
            Verzamelen{" "}
            <span style={{ color: Color.PrimaryOpague }}>van data</span>
          </SectionTitle>
          <>
            <SectionParagraphTitle color={Color.Primary}>
              Koppeling met Power Bi
            </SectionParagraphTitle>
            <SectionParagraph color={Color.Secondary}>
              De data die wordt opgeslagen door de app is opgeslagen in de cloud
              hierdoor is het makkelijk om de data in te zien in verschillende
              Power Bi rapporten.
            </SectionParagraph>
          </>
          <>
            <SectionParagraphTitle color={Color.Primary}>
              Integreren in uw huidige ERP systeem
            </SectionParagraphTitle>
            <SectionParagraph color={Color.Secondary}>
              Deze app kan door productiemedewerkers gebruikt worden op de
              vloer, doormiddel van een tablet of telefoon. Hierdoor
              kan elke werknemer gemakkelijk zijn werk noteren en zijn
              progressie bijhouden.
            </SectionParagraph>
          </>
        </TypographyAnimation>
      </Section>
      <Section sectionElementProps={{ color: Color.Primary }}>
        <PowerBiWidgetContainer>
          <WidgetElement>
            <PageWidget
              title="Power Bi"
              pageLinkText="Meer over onze Power Bi oplossingen"
              path={RoutePath.PowerBi}
              color={Color.Primary}
            />
          </WidgetElement>
        </PowerBiWidgetContainer>
      </Section>
    </Grid>
  )
}
