import { Power3, TweenLite } from "gsap"
import React, { useEffect, useRef } from "react"
import { CtaArrow, Sizes, Rotations } from "../CtaArrow/CtaArrow"
import {
  ContainerElement,
  InnerContainerElement,
  PageLinkElement,
  PageTitleElement,
  ArrowContainerElement,
} from "./styled"
import { Color } from "../../../data/style/variables"
import { Typography, TypographyTypes } from "../Typography/Typography"
import TransitionLink from "gatsby-plugin-transition-link"

export type PageWidgetProps = {
  title: string
  pageLinkText: string
  path: string
  color: Color
}

export const PageWidget: React.FC<PageWidgetProps> = ({
  title,
  pageLinkText,
  path,
  color,
}) => {
  const innerContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    TweenLite.fromTo(
      innerContainerRef.current,
      0.9,
      { opacity: 0, x: -200 },
      { opacity: 1, x: 0, ease: Power3.easeOut }
    ).delay(0.2)
  }, [])

  return (
    <ContainerElement>
      <InnerContainerElement ref={innerContainerRef}>
        <PageTitleElement color={color}>{title}</PageTitleElement>
        <TransitionLink to={path} exit={{ length: 1 }} entry={{ delay: 1 }}>
          <PageLinkElement>
            <Typography
              fontSize={"1.4rem"}
              lineHeight={"2.3rem"}
              titleType={TypographyTypes.a}
              color={color}
            >
              {pageLinkText}
            </Typography>
            <ArrowContainerElement>
              <CtaArrow
                color={color}
                size={Sizes.small}
                rotation={Rotations.right}
              />
            </ArrowContainerElement>
          </PageLinkElement>
        </TransitionLink>
      </InnerContainerElement>
    </ContainerElement>
  )
}
