import React from "react"
import "typeface-roboto-mono"
import { AskForQuote } from "../../general/AskForQuote/AskForQuote"
import { DataIllustrationsSection } from "./sections/DataIllustrationsSection"
import { Hero } from "./sections/Hero"
import { ProductDataSection } from "./sections/ProductDataSection"
import { ProductInfoSection } from "./sections/ProductInfoSections"
import { ProductShowcase } from "./sections/ProductShowcase"
import { ContainerElement } from "./styled"
import { WrapUpSection } from "./sections/WrapUpSection"
import { Footer } from "../Home/sections/Footer/Footer"
import { ProductDatalogicSection } from "./sections/ProductDatalogicSection"

export const Checklist: React.FC = () => {
  return (
    <ContainerElement>
      <Hero />
      <ProductShowcase />
      <ProductInfoSection />
      <ProductDataSection />
      <DataIllustrationsSection />
      <ProductDatalogicSection />
      {/* <WrapUpSection />
      <AskForQuote /> */}
      <Footer />
    </ContainerElement>
  )
}
