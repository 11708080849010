import { graphql, useStaticQuery } from "gatsby"
import { Power3, TweenLite } from "gsap"
import React, { useEffect, useRef, useState } from "react"
import { Grid } from "../../../../components/general/Grid/Grid"
import {
  ProductSlideShow,
  SlideType
} from "../../../../components/general/ProductSlideShow/ProductSlideShow"
import { Section } from "../../../../components/general/Section/Section"
import { SummaryItem } from "../../../../components/general/SummaryItem/SummaryItem"
import { TypographyAnimation } from "../../../../components/general/TypographyAnimation/TypographyAnimation"
import { Color, GrayGridTheme } from "../../../../data/style/variables"
import { CtaArrow, Rotations, Sizes } from "../../../general/CtaArrow/CtaArrow"
import { CtaArrowAnimation } from "../../../general/CtaArrowAnimation/CtaArrowAnimation"
import { PhoneSlideDetailElement } from "../styled"

export const ProductShowcase: React.FC = () => {
  const [activePhoneSlideIndex, setActivePhoneSlideIndex] = useState<number>(0)
  const productSlideShowRef = useRef<HTMLDivElement>(null)
  const scrollCTAArrowRef = useRef<HTMLDivElement>(null)

  const data = useStaticQuery(graphql`
    query {
      slideOne: file(relativePath: { eq: "ChecklistSlides/Overview.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slideTwo: file(relativePath: { eq: "ChecklistSlides/Checks.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slideThree: file(relativePath: { eq: "ChecklistSlides/Qr.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [phoneSlides] = useState<SlideType[]>([
    {
      imageUrl: data.slideOne.childImageSharp.fluid.src,
      name: "Overzicht",
      text: "Een overzichtelijke weergave van de status van het product.",
    },
    {
      imageUrl: data.slideTwo.childImageSharp.fluid.src,
      name: "Vastleggen van fouten",
      text: "Per check foto’s, commentaar en meer details opslaan.",
    },
    {
      imageUrl: data.slideThree.childImageSharp.fluid.src,
      name: "QR code",
      text: "Snel checklisten vinden doormiddel van een QR code.",
    },
  ])

  const handleChangePhoneSlide = (idx: number) => {
    setActivePhoneSlideIndex(idx)
  }

  useEffect(() => {
    TweenLite.fromTo(
      productSlideShowRef.current,
      1.3,
      { opacity: 0, y: 200 },
      { opacity: 1, y: 0, ease: Power3.easeOut }
    ).delay(0.5)
  }, [])

  return (
    <Grid
      gridElementProps={{
        baseHeight: "calc(100vh - 220px)",
        ...GrayGridTheme,
      }}
    >
      <Section sectionElementProps={{ color: Color.Secondary }}>
        <TypographyAnimation>
          {phoneSlides.map((slide, idx) => (
            <PhoneSlideDetailElement
              onClick={() => handleChangePhoneSlide(idx)}
            >
              <SummaryItem
                mainColor={Color.Primary}
                subColor={Color.DarkGray}
                label={`${idx + 1 < 9 ? "0" : ""}${idx + 1}`}
                title={slide.name}
                content={slide.text}
              />
            </PhoneSlideDetailElement>
          ))}
        </TypographyAnimation>
      </Section>
      <Section sectionElementProps={{ color: Color.Secondary }}>
        <div ref={productSlideShowRef}>
          <ProductSlideShow
            slides={phoneSlides}
            activePhoneSlideIndex={activePhoneSlideIndex}
            maxWidth={320}
          />
        </div>
      </Section>
      <Section sectionElementProps={{ color: Color.Secondary }}>
        <CtaArrowAnimation>
          <CtaArrow
            color={Color.LightGray}
            rotation={Rotations.down}
            size={Sizes.large}
          />
        </CtaArrowAnimation>
      </Section>
    </Grid>
  )
}
