import React from "react"
import { Checklist as ChecklistComponent } from "../components/pages/Checklist/Checklist"
import Layout from "../components/layout"
import { Color, HeroGridTheme } from "../data/style/variables"
import SEO from "../components/seo"

const Checklist = () => (
  <Layout navColor={Color.White} borderColor={HeroGridTheme.lineColor}>
    <SEO
      description="Deze app kan door productiemedewerkers gebruikt worden op de vloer, doormiddel van een tablet of telefoon."
      title="Productiefouten opsporen met onze checklist app"
      lang="nl"
    />
    <ChecklistComponent />
  </Layout>
)

export default Checklist
