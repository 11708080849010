import React from "react"
import { Color, FontFamily, FontWeight } from "../../../data/style/variables"
import { Typography, TypographyTypes } from "../Typography/Typography"
import { TypographyAnimation } from "../TypographyAnimation/TypographyAnimation"
import { InfoSummaryItemElement } from "./styled"

type TemplateComponentProps = {
  mainColor: Color
  subColor: Color
  title?: string
  label: string
  content: string
}

export const SummaryItem: React.FC<TemplateComponentProps> = ({
  mainColor,
  subColor,
  title,
  label,
  content,
}) => {
  return (
    <TypographyAnimation>
      <InfoSummaryItemElement content={label} color={mainColor}>
        {title && (
          <Typography
            titleType={TypographyTypes.p}
            fontFamily={FontFamily.RobotoMono}
            color={mainColor}
            fontWeight={FontWeight.Bold}
            fontSize={"2rem"}
          >
            {title}
          </Typography>
        )}
        <Typography
          titleType={TypographyTypes.p}
          fontFamily={FontFamily.RobotoMono}
          color={subColor}
        >
          {content}
        </Typography>
      </InfoSummaryItemElement>
    </TypographyAnimation>
  )
}
