import React from "react"
import { ProductHero } from "../../../general/ProductHero/ProductHero"
import { PageWidget } from "../../../general/PageWidget/PageWidget"
import { useStaticQuery, graphql } from "gatsby"
import { Color } from "../../../../data/style/variables"

export const Hero: React.FC = ({ ...props }) => {
  const imageData = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ProductHero
      title="Productiefouten opsporen met onze checklist app"
      image={imageData.heroImage.childImageSharp.fluid.src}
      technicalDetails="PWA - web - gebruiksvriendelijk"
      infoTexts={["Controle","Efficiëntie","Optijd bijsturen"]}
      pageWidget={
        <PageWidget
          title="Mobiel"
          pageLinkText="Meer over onze mobiele applicaties"
          path=""
          color={Color.White}
        />
      }
    />
  )
}
